import { SET_FLASH_MESSAGE, UPDATE_FLASH_MESSAGE } from '../actions/flashMessage';

// reducer to handling flash messages
export function flashMessage(state = {}, action) {
  let newState = { ...state };
  switch (action.type) {
    case SET_FLASH_MESSAGE:
    case UPDATE_FLASH_MESSAGE:
      newState['data'] = action.payload;
      return newState;

    default:
      return state;
  }
}
