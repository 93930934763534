// © 2019, Left Hook
// FreshBooks-Other Income Integration UI
// ===============================================

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reducer from './reducers';
import middleware from './middleware';

const clientAppType = process.env.REACT_APP_CLIENT_APP_TYPE;
if (clientAppType === 'hubspot') {
  document.title = 'FreshBooks - HubSpot';
} else if (clientAppType === 'income_importer') {
  document.title = 'FreshBooks - Income Importer';
} else {
  document.title = 'FreshBooks';
}

// create the redux store with the combined reducers and the combined middleware
const store = createStore(reducer, middleware);

// Get the root element
const container = document.getElementById('root');

// Create a root
const root = createRoot(container);

// Initial render
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
