import React, { useState } from 'react';
import API from '../../api';
import JSONPretty from 'react-json-pretty';

const requests = [
  {
    name: 'Get Cookie',
    value: 'getAcitveIntegrations',
    params: [],
    send: async () => {
      return await API.action.dev.getToken();
    },
  },
  {
    name: 'Get Acitve Integrations',
    value: 'getActiveIntegrations',
    params: [
      {
        name: 'FreshBooks Entity ID',
        type: 'entity',
      },
    ],
    send: async (freshbooksEntityID) => {
      return await API.action.getActiveIntegrations(freshbooksEntityID);
    },
  },
  {
    name: 'Get Integration Sources',
    value: 'getIntegrationSources',
    params: [],
    send: async () => {
      return await API.action.getIntegrationSources();
    },
  },
  {
    name: 'Get Selection Options',
    value: 'getSelectionOptions',
    params: [
      {
        name: 'Credential ID',
        type: 'credential',
      },
    ],
    send: async (credentialId) => {
      return await API.action.getSelectionOptions({ credentialId: credentialId });
    },
  },
  {
    name: 'Create Entity',
    value: 'createEntity',
    params: [
      {
        name: 'Type',
        type: 'integrationType',
      },
      {
        name: 'Credential ID',
        type: 'credential',
      },
      {
        name: 'Account ID',
        type: 'input',
      },
      {
        name: 'Shop ID',
        type: 'input',
      },
    ],
    send: async (integrationType, credentialId, accountId, shopId) => {
      let reqObj = {
        integrationType: integrationType,
        entityDetails: { credentials: credentialId },
      };
      if (accountId !== 'default') {
        reqObj.entityDetails.account_id = accountId;
      } else if (shopId !== 'default') {
        reqObj.entityDetails.shop_id = shopId;
      } else {
        return { error: 'Invalid input' };
      }
      return await API.action.createEntity(reqObj);
    },
  },
  {
    name: 'Delete Integration',
    value: 'deleteIntegration',
    params: [],
    send: API.action.deleteIntegration,
  },
  {
    name: 'Start Sync',
    value: 'startSync',
    params: [],
    send: API.action.startSync,
  },
  {
    name: 'Check Sync',
    value: 'checkSync',
    params: [],
    send: API.action.checkSync,
  },
];

const RequestSender = (props) => {
  const [state, setState] = useState({
    reponse: null,
    params: [],
  });

  let requestObject = props.requestObject;
  if (requestObject === null) {
    return <h5>Select a Request</h5>;
  }

  if (requestObject.params.length > 0 && state.params.length === 0) {
    let __state = state;
    requestObject.params.forEach(() => {
      __state.params.push('default');
    });
    setState({ ...__state });
  }

  const setParam = (value, index) => {
    let _state = state;
    _state.params[index] = value;
    setState({ ..._state });
  };

  const setResponse = (responseObject) => {
    let _state = state;
    _state.response = responseObject;
    setState({ ..._state });
  };

  const renderParams = () => {
    if (requestObject.params.length === 0) {
      return <p> None </p>;
    } else {
      return requestObject.params.map((param, r) => {
        let inputElement;

        if (param.type === 'entity') {
          inputElement = (
            <select value={state.params[r]} onChange={(e) => setParam(e.target.value, r)}>
              <option value="default"> Select Entity </option>
              {props.config.entities.map((ent, y) => {
                return (
                  <option value={ent.id} key={y * 71.29}>
                    {' '}
                    {ent.type} -- {ent.id}{' '}
                  </option>
                );
              })}
            </select>
          );
        } else if (param.type === 'credential') {
          inputElement = (
            <select value={state.params[r]} onChange={(e) => setParam(e.target.value, r)}>
              <option value="default"> Select Credential </option>
              {props.config.credentials.map((cred, y) => {
                return (
                  <option value={cred.id} key={y * 61.29}>
                    {' '}
                    {cred.type} -- {cred.id}{' '}
                  </option>
                );
              })}
            </select>
          );
        } else if (param.type === 'integrationType') {
          inputElement = (
            <select value={state.params[r]} onChange={(e) => setParam(e.target.value, r)}>
              <option value="default"> Credential Type </option>
              <option value="FreshBooks"> FreshBooks </option>
              <option value="Etsy"> Etsy </option>
              <option value="Square"> Square </option>
              <option value="Stripe"> Stripe </option>
            </select>
          );
        } else {
          inputElement = (
            <input value={state.params[r]} onChange={(e) => setParam(e.target.value, r)} />
          );
        }

        return (
          <div key={r * 3.0125}>
            <span>{param.name}</span>
            <br />
            {inputElement}
            <br />
            <br />
          </div>
        );
      });
    }
  };

  const sendRequest = async (params) => {
    let response = await requestObject.send(params);
    console.log(response);
    setResponse(response);
  };

  let responseJSX;

  if (state.response) {
    responseJSX = (
      <div>
        <h5> Response </h5>
        <hr />
        <JSONPretty data={state.response} />
      </div>
    );
  } else {
    responseJSX = <></>;
  }

  return (
    <>
      <h5> {requestObject.name} </h5>
      <b> Params </b>
      {renderParams()}
      <br />
      <button
        onClick={() => {
          sendRequest(...state.params);
        }}
      >
        {' '}
        Send{' '}
      </button>
      <br />
      {responseJSX}
    </>
  );
};

const Requests = (props) => {
  const [formValues, setFormValues] = useState({
    requestType: 'default',
    requestObject: null,
    response: null,
  });

  // eslint-disable-next-line
  const setRequestObject = (requestObject) => {
    let _state = formValues;
    _state.requestObject = requestObject;
    setFormValues({ ..._state });
  };

  const setRequestType = (requestType) => {
    let _state = formValues;
    _state.requestType = requestType;
    setFormValues({ ..._state });
  };

  const getRequestObj = () => {
    for (let p = 0; p < requests.length; p++) {
      let req = requests[p];
      if (req.value === formValues.requestType) {
        return req;
      }
    }
    return null;
  };

  return (
    <>
      <h3> Requests </h3>
      <hr />
      <span> Select Request </span>
      <select value={formValues.requestType} onChange={(e) => setRequestType(e.target.value)}>
        <option value="default"> Default </option>
        {requests.map((r, n) => {
          return (
            <option key={n * 8.241} value={r.value}>
              {r.name}
            </option>
          );
        })}
      </select>
      <hr />
      <RequestSender requestObject={getRequestObj()} config={props.config} />
    </>
  );
};

export default Requests;
