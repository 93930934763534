import React from 'react';

const Loading = () => {
  return (
    <>
      <div className="dialogue md">
        <h2 className="variant-blue x-light"> Loading </h2>
        <br />
        <h3 className="variant-blue loading-animated"> .... </h3>
      </div>
    </>
  );
};

export default Loading;
