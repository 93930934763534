export const SET_FLASH_MESSAGE = 'SET_FLASH_MESSAGE';
export const UPDATE_FLASH_MESSAGE = 'UPDATE_FLASH_MESSAGE';

const initialState = {
  text: '',
  active: false,
  type: '',
};

// an action to set a flash message
export function setFlashMessage(message = initialState) {
  return {
    type: SET_FLASH_MESSAGE,
    payload: message,
  };
}

// an action fot update flash state
export function updateFlashMessage(message) {
  return {
    type: UPDATE_FLASH_MESSAGE,
    payload: { ...message },
  };
}
