import React, { useState } from 'react';
import caret_icon from '../../../assets/media/carat.png';

interface IDropdownProps {
  placeholder: string;
  value: string | number;
  options: { label: string; value: string | number }[];
  onChange: (value: string | number) => any;
}

const Dropdown = ({ placeholder, value, options, onChange }: IDropdownProps) => {
  const [open, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  return (
    <div className={`tpc-dropdown ${open ? 'tpc-dropdown-open' : ''}`}>
      {open && <div className="tpc-dropdown-capture" onClick={onClose} />}
      <button className="tpc-dropdown-action" onClick={onOpen}>
        {placeholder} <img src={caret_icon} />
      </button>
      <div className="tpc-dropdown-menu-wrapper">
        {open && (
          <div className="tpc-dropdown-menu">
            {options.map((option) => (
              <div
                key={`dropdown-option-${option.value}`}
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(option.value);
                  onClose();
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
