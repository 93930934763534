import Action from './action';
import Wrap from './wrap';
import Util from './util';
import ApiError from './error';

class API {
  static constants = {
    endpoints: {
      integrationSources: '/api/integrations',
      activeIntegrations: '/api/integrations',
      integration: '/api/integrations',
      updateIntegration: '/api/integrations/:integrationId',
      searchOrCreateIntegration: '/api/integrations',
      authorizeURI: '/api/authorize',
      authorizeCallback: '/api/authorize',
      authorizeAppURI: '/user/authorize',
      authorizeAppCallback: '/user/authorize',
      entity: '/api/entities',
      getSelectionOptions: '/api/credentials',
      startSync: '/api/integrations/:integrationId/sync',
      executeAction: '/api/integrations/:integrationId/actions/:actionId',
      actionProcessRecord: '/api/integrations/:integrationId/actions/:actionId/:processId',
      checkSync: '/api/integrations/:integrationId/sync',
      sync: '/api/integrations/:integrationId/sync',
      integrationStatus: '/api/integrations/:integrationId/status',
      //deleteIntegration: '/api/deauthorize'
    },
    //backendURI : 'http://localhost:3000',
    backendURI: process.env.REACT_APP_API_URL,
    selfURI: process.env.REACT_APP_SELF_URL,
    //selfURI : 'https://localhost:3000'
  };

  static action = Action;
  static wrap = Wrap;
  static util = Util;
  static error = ApiError;

  //   static transformResponse = (data: any) => {
  //     return data;
  //   };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static handleRequestError(e: any) {
    // Add more robust handling for this later...
    if (e?.response?.status === 401) {
      sessionStorage.removeItem('lha');
      window.location.href = '/';
    }
    console.error(e);
  }

  static async request({
    path,
    method,
    body,
  }: {
    path: string;
    method: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: any;
  }) {
    console.log('request: ' + path);
    console.log('baseUrl: ' + API.constants.backendURI);

    const headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
    };
    const existingLHSet = localStorage.getItem('x-lh-set');
    if (existingLHSet) {
      headers['X-LH-Auth'] = existingLHSet;
    }

    const requestOptions: RequestInit = {
      method: method,
      headers,
    };

    if (body) requestOptions.body = JSON.stringify(body);
    try {
      console.log(requestOptions);
      const response = await fetch(`${API.constants.backendURI}${path}`, requestOptions);

      const responseLHSet = response.headers.get('x-lh-set');
      if (responseLHSet) {
        localStorage.setItem('x-lh-set', responseLHSet);
      }

      console.log(response);
      if (response.status === 204) return;
      return response.json();
    } catch (error) {
      API.handleRequestError(error);
    }
  }
}

export default API;
