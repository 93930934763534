import {
  IActiveIntegration,
  IAuthorizationCredentials,
  IEntity,
  IEntityDetails,
  IIntegrationById,
  IIntegrationSources,
  IIntegrationStatus,
  ISyncProcessRecord,
  ISyncStatus,
} from '../../types/interfaces';
import API from './api';

class Action {
  static getClientAppType() {
    return process.env.REACT_APP_CLIENT_APP_TYPE || 'income_importer'; // "income_importer" or "hubspot"
  }

  static dev = {
    async getToken() {
      const requestObject = {
        path: '/dev/token',
        method: 'GET',
      };
      const response = await API.request(requestObject);
      return response;
    },
  };

  static async getIntegrationById(integrationId: string) {
    const requestObject = {
      path: `${API.constants.endpoints.integration}/${integrationId}`,
      method: 'GET',
    };
    const response = await API.request(requestObject);
    return response as IIntegrationById;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async updateIntegration(integrationId: string, config: any) {
    const requestObject = {
      path: API.constants.endpoints.updateIntegration.replace(':integrationId', integrationId),
      method: 'PATCH',
      body: {
        config,
      },
    };
    const response = await API.request(requestObject);
    return response;
  }

  static async getActiveIntegrations(freshbooksEntityId: string) {
    const requestObject = {
      path: `${
        API.constants.endpoints.activeIntegrations
      }?entity_1=${freshbooksEntityId}&clientAppType=${Action.getClientAppType()}`,
      method: 'GET',
    };
    const response = await API.request(requestObject);

    return response.integrations as IActiveIntegration[];
  }

  static async getIntegrationSources() {
    const requestObject = {
      path: `${
        API.constants.endpoints.integrationSources
      }?clientAppType=${Action.getClientAppType()}`,
      method: 'GET',
    };
    const response = await API.request(requestObject);
    console.log('integrationSources', response);
    return response as IIntegrationSources;
  }

  static async integrationSources() {
    const requestObject = {
      path: `${
        API.constants.endpoints.integrationSources
      }?clientAppType=${Action.getClientAppType()}`,
      method: 'GET',
    };
    const response = await API.request(requestObject);
    console.log('integrationSources', response);
    return response;
  }

  static async getAuthorizeUri({
    integrationType,
    redirect_uri,
  }: {
    integrationType: string;
    redirect_uri?: string;
  }) {
    console.log('getAuthorizeUri', integrationType, redirect_uri, Action.getClientAppType());

    let queryString = `?type=${integrationType}&entityType=${integrationType}&subType=${Action.getClientAppType()}`;
    if (redirect_uri) {
      queryString += `&redirect_uri=${redirect_uri}`;
    }

    const uri =
      integrationType == 'freshbooks'
        ? `${API.constants.endpoints.authorizeAppURI}`
        : `${API.constants.endpoints.authorizeURI}`;

    const requestObject = {
      path: `${uri}${queryString}`,
      method: 'GET',
    };
    const response = await API.request(requestObject);
    return response as string | { url?: string; redirect_uri?: string };
  }

  static async authorizeCallback({
    authorizationCredentials,
  }: {
    authorizationCredentials: IAuthorizationCredentials;
  }) {
    console.log('authorizeCallback', authorizationCredentials);

    const uri =
      authorizationCredentials.type == 'freshbooks'
        ? `${API.constants.endpoints.authorizeAppCallback}`
        : `${API.constants.endpoints.authorizeCallback}`;

    const requestObject = {
      path: uri,
      method: 'POST',
      body: {
        ...authorizationCredentials,
        subType: Action.getClientAppType(),
        redirect_uri: API.constants.selfURI + '/redirect',
      },
    };
    const response = await API.request(requestObject);
    return response;
  }

  static async getSelectionOptions({
    credentialId,
    entityType,
  }: {
    credentialId: string;
    entityType: string;
  }) {
    const requestObject = {
      path: `${
        API.constants.endpoints.getSelectionOptions
      }/${credentialId}/entity/options?entityType=${String(entityType).toLowerCase()}`,
      method: 'GET',
    };
    const response = await API.request(requestObject);
    return response;
  }

  // create an integration (a connection of two entities).
  // example, create an integration between FreshBooks and Etsy where the integration type is Etsy,
  // and the entity_1, entity_2 are their entity _ids respectively
  static async searchOrCreateIntegration(
    integrationType: string,
    entity_1: string,
    entity_2: string,
  ) {
    const requestObject = {
      path: API.constants.endpoints.searchOrCreateIntegration,
      method: 'POST',
      body: {
        entities: [entity_1, entity_2],
        config: {
          type: String(integrationType).toLowerCase(),
        },
      },
    };
    const response = await API.request(requestObject);
    return response;
  }

  // search or create an Entity
  static async searchOrCreateEntity({
    integrationType,
    entityDetails,
  }: {
    integrationType: string;
    entityDetails: IEntityDetails;
  }) {
    const clientAppType = process.env.REACT_APP_CLIENT_APP_TYPE || 'income_importer'; // "income_importer" or "hubspot"
    const requestObject = {
      path: API.constants.endpoints.entity,
      method: 'POST',
      body: {
        entityType: String(integrationType).toLowerCase(),
        data: {
          credential_id: entityDetails.credential_id,
          account_id: entityDetails.account_id,
          subType: clientAppType,
        },
      },
    };
    const response = await API.request(requestObject);
    return response as IEntity;
  }

  // delete an integration object by specifying its _id
  static async deleteIntegration(integrationId: string) {
    const requestObject = {
      path: `${API.constants.endpoints.integration}/${integrationId}`,
      method: 'DELETE',
    };
    const response = await API.request(requestObject);
    return response;
  }

  static async executeUserAction(
    syncConfigurationDetails = {
      integrationId: '',
      actionId: '',
      data: {},
    },
  ) {
    const requestObject = {
      path: `${API.constants.endpoints.executeAction
        .replace(':integrationId', syncConfigurationDetails.integrationId)
        .replace(':actionId', syncConfigurationDetails.actionId)}`,
      method: 'POST',
      body: syncConfigurationDetails.data,
    };
    const response = await API.request(requestObject);
    return response;
  }

  static async getActionProcessRecord(
    integrationId: string,
    actionId: string,
    processId: string,
  ): Promise<ISyncProcessRecord> {
    const requestObject = {
      path: `${API.constants.endpoints.actionProcessRecord
        .replace(':integrationId', integrationId)
        .replace(':actionId', actionId)
        .replace(':processId', processId)}`,
      method: 'GET',
    };
    return API.request(requestObject);
  }

  static async getIntegrationStatus(integrationId: string) {
    const requestObject = {
      path: `${API.constants.endpoints.integrationStatus.replace(':integrationId', integrationId)}`,
      method: 'GET',
    };
    const response = await API.request(requestObject);
    return response as IIntegrationStatus;
  }

  static async startSync(
    syncConfigurationDetails = {
      integrationId: '',
      startDate: '',
      appName: '',
    },
  ) {
    const requestObject = {
      path: `${API.constants.endpoints.startSync.replace(
        ':integrationId',
        syncConfigurationDetails.integrationId,
      )}`, ///${syncConfigurationDetails.integrationId}`,
      method: 'POST',
      body: { startDate: syncConfigurationDetails.startDate },
    };
    const response = await API.request(requestObject);
    return response;
  }

  static async checkSync(
    syncConfigurationDetails = {
      integrationId: '',
    },
  ) {
    const requestObject = {
      path: `${API.constants.endpoints.sync.replace(
        ':integrationId',
        syncConfigurationDetails.integrationId,
      )}/status`,
      method: 'GET',
    };
    const response = await API.request(requestObject);
    return response as ISyncStatus;
  }

  static async enableOngoingSync(
    syncConfigurationDetails = {
      integrationId: '',
    },
  ) {
    const requestObject = {
      path: `${API.constants.endpoints.sync.replace(
        ':integrationId',
        syncConfigurationDetails.integrationId,
      )}/on`,
      method: 'PATCH',
    };
    const response = await API.request(requestObject);
    return response;
  }

  static async disableOngoingSync(
    syncConfigurationDetails = {
      integrationId: '',
    },
  ) {
    const requestObject = {
      path: `${API.constants.endpoints.sync.replace(
        ':integrationId',
        syncConfigurationDetails.integrationId,
      )}/off`,
      method: 'PATCH',
    };
    const response = await API.request(requestObject);
    return response;
  }
}

export default Action;
