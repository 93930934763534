import { IAppState } from '../../types/interfaces';

export const CONFIGURE_SCREENS = {
  loading: 'loading',
  selectFreshbooksBusiness: 'selectFreshbooksBusiness',
  // selectEtsyStore: 'selectEtsyStore',
  configureIntegrations: 'configureIntegerations',
  // syncInProgress : 'syncInProgress'
};

export const CONFIGURE_NEXT_ACTION = {
  shouldRegister: 'shouldRegister',
  refreshAndLoad: 'refreshAndLoad',
  clearAndSelect: 'clearAndSelect',
  showBusinessSelect: 'showBusinessSelect',
};

export const parseStateParam = (qState: string) => {
  try {
    qState = String(qState);
    const JSONqState = JSON.parse(qState);
    console.log(JSONqState.app);

    // let statePattern = new RegExp("(\w+?):(\w+?)", "gi")
    if (JSONqState.app) {
      return JSONqState;
    }

    const matchesPattern = /(\w+)?:(\w+)?/i.test(qState);
    if (matchesPattern) {
      const parsedParam: RegExpExecArray | null = /(\w+)?:(\w+)?/i.exec(qState);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const paramObj: any = {};
      if (parsedParam && parsedParam[1] && parsedParam[2]) {
        paramObj[parsedParam[1]] = parsedParam[2];
      }

      return paramObj;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const confirmViableState = (lss: string) => {
  let viable = false;
  const stateObject = JSON.parse(lss) as IAppState;
  if (stateObject) {
    if (
      stateObject.freshbooks.credentialId &&
      stateObject.freshbooks.businessMemberships.length > 0
    ) {
      viable = true;
    }
  }
  return viable;
};

export const confirmViableAppState = (stateObject: IAppState | null) => {
  let viable = false;
  if (stateObject) {
    if (
      stateObject.freshbooks.credentialId &&
      stateObject.freshbooks.businessMemberships.length > 0
    ) {
      viable = true;
    }
  }
  return viable;
};

export const setLocalAppState = async (appState: IAppState) => {
  await localStorage.setItem('lss', JSON.stringify(appState));
};

export const getLocalAppState = () => {
  const lss = localStorage.getItem('lss');

  return lss ? (JSON.parse(lss) as IAppState) : null;
};

export const getInitialState = () => {
  const initialState: IAppState = {
    freshbooks: {
      businessMemberships: [],
      userInstance: null,
      currentBusiness: null,
      credentialId: null,
      entity: null,
    },
    thirdPartyEntities: [],
    activeIntegrations: [],
    integrationSources: null,
    screen: CONFIGURE_SCREENS.loading,
    showPopup: false,
  };

  return initialState;
};
