const CONFIG_TITLE = {
  hubspot: 'Connect',
  income_importer: 'Income Importer',
};
const CONFIG_SUB_HEADER = {
  hubspot:
    'Connect your HubSpot portal below to get started and run your first sync. Come back here to manage your integration settings whenever you need! Please check our help docs for a full review of features.',
  income_importer:
    'Connect and manage the platforms you&apos;d like to include in Income\n' +
    '          Importer so that we can capture your full revenue stream.',
};

export { CONFIG_TITLE, CONFIG_SUB_HEADER };
