import React, { useEffect, useState } from 'react';
import API from '../../api/api';
import close_icon from '../../assets/media/close-2.svg';
import { ISyncProcessRecord } from '../../../types/interfaces';

interface ISyncStatus {
  otherIncomeTransactions: number | string;
  otherIncomeAmounts: string[];
  expensesTransactions: number | string;
  expensesAmounts: string[];
}

interface ISyncCompleteProps {
  integrationId: string;
  appName: string;
  closePopup: () => void;
  actionId: string;
}

const SyncComplete = ({ integrationId, appName, closePopup, actionId }: ISyncCompleteProps) => {
  const [syncStats, setSyncStats] = useState<ISyncStatus>({
    otherIncomeTransactions: '',
    otherIncomeAmounts: [],
    expensesTransactions: '',
    expensesAmounts: [],
  });

  useEffect(() => {
    async function exec() {
      try {
        const integration = await API.action.getIntegrationById(integrationId);
        const processId = integration.config.lastSyncProcessId;
        const syncProcessResults: ISyncProcessRecord = await API.action.getActionProcessRecord(
          integrationId,
          actionId,
          processId,
        );
        const syncStats: ISyncStatus = {
          otherIncomeTransactions: syncProcessResults.results.otherIncome?.transactions ?? 0,
          otherIncomeAmounts: [],
          expensesTransactions: syncProcessResults.results.expenses?.transactions ?? 0,
          expensesAmounts: [],
        };

        for (const key in syncProcessResults.results.otherIncome?.amounts) {
          // skip loop if the property is from prototype
          // eslint-disable-next-line no-prototype-builtins
          if (!syncProcessResults.results.otherIncome.amounts.hasOwnProperty(key)) continue;

          const amount = syncProcessResults.results.otherIncome.amounts[key];
          const currency = key;
          const formattedAmt = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            style: 'currency',
            currency,
          }).format(amount);
          syncStats.otherIncomeAmounts.push(formattedAmt);
        }
        for (const key in syncProcessResults.results.expenses?.amounts) {
          // skip loop if the property is from prototype
          // eslint-disable-next-line no-prototype-builtins
          if (!syncProcessResults.results.expenses.amounts.hasOwnProperty(key)) continue;

          const amount = syncProcessResults.results.expenses.amounts[key];
          const currency = key;
          const formattedAmt = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            style: 'currency',
            currency,
          }).format(amount);
          syncStats.expensesAmounts.push(formattedAmt);
        }

        setSyncStats(syncStats);
        if (!integration.config.ongoingSync) {
          // Toggle the integration to "ON"
          await API.action.enableOngoingSync({
            integrationId: integrationId,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

    exec();
  }, [integrationId]);

  return (
    <>
      <div className={'popup'}>
        <div className={'sync-complete dialogue md popup-main'}>
          <img src={close_icon} className="close" onClick={closePopup} />
          <h2 className={'variant-blue x-light'}> Sync Complete </h2>
          <p className={'fb-copy'}>
            Your information has been synced to FreshBooks. The below numbers represent all
            transactions found in your {appName} account from the provided date. Any previously
            synced transactions have not been imported.
          </p>
          <br />
          {syncStats.otherIncomeTransactions !== '' ? (
            <p className={'sync-stats fb-copy'}>
              Transactions Synced: {syncStats.otherIncomeTransactions}
            </p>
          ) : null}
          {syncStats.otherIncomeAmounts.length > 0 ? (
            <p className={'sync-stats fb-copy'}>
              Transactions Value: {syncStats.otherIncomeAmounts.join(' and ')}
            </p>
          ) : null}
          {syncStats.expensesTransactions !== '' ? (
            <p className={'sync-stats fb-copy'}>
              Expenses Synced: {syncStats.expensesTransactions}
            </p>
          ) : null}
          {syncStats.expensesAmounts.length > 0 ? (
            <p className={'sync-stats fb-copy'}>
              Expenses Value: {syncStats.expensesAmounts.join(' and ')}
            </p>
          ) : null}
          <br />
          <button className={'fb-primary'} onClick={closePopup}>
            Ok, Got It
          </button>
        </div>
      </div>
    </>
  );
};

export default SyncComplete;
