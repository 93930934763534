import React, { useEffect } from 'react';
import API from '../../api';
import qString from 'query-string';
import Dialogues from '.';
import { getLocalAppState } from '../../utils/stateUtils';

async function openExternalAuthURL() {
  try {
    const authURI = await API.action.getAuthorizeUri({
      integrationType: 'freshbooks',
      redirect_uri: encodeURIComponent(API.constants.selfURI + '/redirect'),
    });
    //window.open(authURI.redirect_uri, null,);
    console.log('openExternalAuthURL', authURI);

    if (typeof authURI !== 'string') {
      const redirectURL = authURI?.url || authURI?.redirect_uri;
      if (redirectURL) {
        window.location.href = redirectURL;
      }
    }
  } catch (e) {
    console.error(e);
  }
}

const Login = () => {
  useEffect(() => {
    async function exec() {
      const params = qString.parse(window.location.search);
      console.log('params: ' + JSON.stringify(params));

      /*
       * TODO (We can remove?) Logic to see if we have all of the pieces we need...
       *  We need:
       *  1) lha, not expired,
       *  2) last saved state with at least FB Entity ID and Business Memberships
       *  And optionally:
       *  3) if accountid param is present, store it
       *
       * TODO Logic for Login...
       *  1. if lha is expired or not present, redirect for auth flow
       *  2. if lss is null and/or if FB Entity ID && Business Memberships do not exist, redirect for auth flow
       *   (because we need FB entity id and business memberships to at least force selection)
       *  3. Otherwise, we can redirect user to /config
       *
       * TODO Logic for Config...
       *  1. if accountid session storage is present & "Selected Business" is not the same OR has nothing, pull the Business Membership and register it as a selection
       *  2. if accountid session storage is NOT present, and "Selected Business" has nothing, then force selection
       *
       * TODO Logic for Config when loaded with ?code param
       *  1. If code is present... parse "state", and get App name. Then make the callback/auth request.
       *  2. If app is FB... Callback, save credential and Business Memberships to state, then do the account ID/Selection logic for Entity
       *  3. If App is NOT FB... Callback, use returned credential and freshbooks credential to create an integration
       *
       * TODO Logic for Redirect...
       *  1. Redirect to Config with params intact
       *  (2. Remove the other logic)
       *
       * */

      if (params['accountid'] && typeof params['accountid'] === 'string') {
        localStorage.setItem('accountid', params.accountid);
      } else {
        console.log('query did not have accountid');
      }

      const lha = sessionStorage.getItem('lha');
      console.log('lha: ' + lha);
      // Great, we have a last saved state, but we need to figure out if we have entity id and business memberships, otherwise... auth flow!
      try {
        const stateObject = getLocalAppState();
        if (
          !stateObject?.freshbooks?.credentialId ||
          !stateObject?.freshbooks.businessMemberships ||
          stateObject?.freshbooks.businessMemberships.length === 0 ||
          !lha
        ) {
          console.log('No FB Credentials... need auth!');

          return openExternalAuthURL();
        }

        window.location.href = '/config';
      } catch (error) {
        console.error(error);
        return openExternalAuthURL();
      }
    }
    exec();
  }, []);
  return (
    <>
      <Dialogues.Loading />
    </>
  );
};

export default Login;
