class Integration {
  constructor(data) {
    this.getId = () => data.id;
    this.getType = () => data.config.type;
    this.getEntities = () => [data.entities[0], data.entities[1]];
    this.getLastSyncStart = () => data.config.lastSyncStart;
    this.getLastSyncEnd = () => data.config.lastSyncEnd;
    this.getStatus = () => data.status;
    this.getOngoingSyncStatus = () => data.config.ongoingSync;
  }
}

export default Integration;
