import React from 'react';
import Forms from './forms';
import IntegrationSourceParserViewer from './integrationSourceParserViewer';
import './test.css';
class ParentWrapper extends React.Component {
  static screeens = {
    default: 'default',
    setConfigItems: 'setConfigItems',
    requests: 'requests',
    components: 'components',
    integrationSourceParser: 'integrationSourceParser',
  };

  setScreen(screen) {
    let _state = this.state;
    _state.screen = screen;
    this.setState({ ..._state });
  }

  addCredential(cred) {
    let _state = this.state;
    _state.config.credentials.push(cred);
    this.setState({ ..._state });
  }

  addEntity(entity) {
    let _state = this.state;
    _state.config.entities.push(entity);
    this.setState({ ..._state });
  }

  constructor(props) {
    super(props);

    this.state = {
      screen: ParentWrapper.screeens.default,
      config: {
        credentials: [],
        entities: [],
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    localStorage.setItem('lastUpdatedState', JSON.stringify(prevState));
  }

  componentDidMount() {
    if (this.state.screen === ParentWrapper.screeens.default) {
      let lastUpdatedState = localStorage.getItem('lastUpdatedState');
      if (lastUpdatedState) {
        try {
          let _state = JSON.parse(lastUpdatedState);
          this.setState({ ..._state });
        } catch (error) {
          console.error(error);
        }
      }
    }
  }

  getBody() {
    switch (this.state.screen) {
      case ParentWrapper.screeens.setConfigItems:
        return (
          <Forms.SetConfigItems
            config={this.state.config}
            addCredential={(c) => this.addCredential(c)}
            addEntity={(e) => this.addEntity(e)}
          />
        );
      case ParentWrapper.screeens.requests:
        return <Forms.Requests config={this.state.config} />;
      case ParentWrapper.screeens.integrationSourceParser:
        return <IntegrationSourceParserViewer config={this.state.config} />;
      case ParentWrapper.screeens.default:
      default:
        return <></>;
    }
  }

  render() {
    return (
      <>
        <div id="test-main">
          <div id="test-header">
            <b> Income Importer Tests </b>
          </div>
          <div id="test-menu">
            <span
              className="test-menu-item"
              onClick={() => this.setScreen(ParentWrapper.screeens.setConfigItems)}
            >
              {' '}
              Set Config Items{' '}
            </span>
            <hr />
            <span
              className="test-menu-item"
              onClick={() => this.setScreen(ParentWrapper.screeens.requests)}
            >
              {' '}
              Requests{' '}
            </span>
            <hr />
            <span
              className="test-menu-item"
              onClick={() => this.setScreen(ParentWrapper.screeens.components)}
            >
              {' '}
              Components{' '}
            </span>
            <hr />
            <span
              className="test-menu-item"
              onClick={() => this.setScreen(ParentWrapper.screeens.integrationSourceParser)}
            >
              {' '}
              Integration Source Parser{' '}
            </span>
            <hr />
          </div>
          <div id="test-body">{this.getBody()}</div>
        </div>
      </>
    );
  }
}

export default ParentWrapper;
