import React from 'react';
import Login from '../components/dialogues/login';

function Landing() {
  return (
    <>
      <div id={'main'} className={''}>
        <Login />
      </div>
    </>
  );
}

export default Landing;
