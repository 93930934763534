import React, { useState } from 'react';

const SetConfigItems = (props) => {
  const [formValues, setFormValues] = useState({
    credentialType: 'default',
    credentialId: '',
    entityType: 'default',
    entityId: '',
  });

  const clearForm = () => {
    setFormValues({
      credentialType: 'default',
      credentialId: '',
      entityType: 'default',
      entityId: '',
    });
  };

  const setCredentialType = (type) => {
    let _state = formValues;
    _state.credentialType = type;
    setFormValues({ ..._state });
  };

  const setCredentialId = (id) => {
    let _state = formValues;
    _state.credentialId = id;
    setFormValues({ ..._state });
  };

  const setEntityType = (type) => {
    let _state = formValues;
    _state.entityType = type;
    setFormValues({ ..._state });
  };

  const setEntityId = (id) => {
    let _state = formValues;
    _state.entityId = id;
    setFormValues({ ..._state });
  };

  return (
    <>
      <h3> Set Config Items </h3>
      <hr />
      <h5> Credentials </h5>
      <span> TYPE </span>
      <select value={formValues.credentialType} onChange={(e) => setCredentialType(e.target.value)}>
        <option value="default"> Credential Type </option>
        <option value="FreshBooks"> FreshBooks </option>
        <option value="Etsy"> Etsy </option>
        <option value="Square"> Square </option>
        <option value="Stripe"> Stripe </option>
      </select>
      <span> CREDENTIAL ID </span>
      <input value={formValues.credentialId} onChange={(e) => setCredentialId(e.target.value)} />
      <button
        onClick={() => {
          props.addCredential({ type: formValues.credentialType, id: formValues.credentialId });
          clearForm();
        }}
      >
        {' '}
        Add Credential{' '}
      </button>
      <br />
      <br />
      <b> Current Credentials </b>
      {props.config.credentials.map((cred, n) => {
        return (
          <div key={n * 5.56}>
            <span> TYPE : {cred.type} </span>
            <br />
            <span> ID : {cred.id} </span>
            <br />
          </div>
        );
      })}
      <br />
      <hr />
      <h5> Entities </h5>
      <span> TYPE </span>
      <select value={formValues.entityType} onChange={(e) => setEntityType(e.target.value)}>
        <option value="default"> Entity Type </option>
        <option value="FreshBooks"> FreshBooks </option>
        <option value="Etsy"> Etsy </option>
        <option value="Square"> Square </option>
        <option value="Stripe"> Stripe </option>
      </select>
      <span> ENTITY ID </span>
      <input value={formValues.entityId} onChange={(e) => setEntityId(e.target.value)} />
      <button
        onClick={() => {
          props.addEntity({ type: formValues.entityType, id: formValues.entityId });
          clearForm();
        }}
      >
        {' '}
        Add Entity{' '}
      </button>
      <br />
      <br />
      <b> Current Entities </b>
      {props.config.entities.map((entity, n) => {
        return (
          <div key={n * 11.56}>
            <span> TYPE : {entity.type} </span>
            <br />
            <span> ID : {entity.id} </span>
            <br />
            <br />
          </div>
        );
      })}
    </>
  );
};

export default SetConfigItems;
