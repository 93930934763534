import React, { useEffect, useState } from 'react';
import dropdown_toggle from '../../assets/media/carat.png';
import {
  ActiveIntegrationStatusEnum,
  IActiveIntegration,
  IIntegrationSource,
  IUserAction,
} from '../../../types/interfaces';
import ExecuteUserAction from '../dialogues/execute_user_action';
import { JsonForms } from '@jsonforms/react';
import { JsonFormsCore } from '@jsonforms/core';
import { vanillaCells, vanillaRenderers } from '@jsonforms/vanilla-renderers';
import { ToggleSwitchControl } from '../controls/ToggleSwitchControl';
import API from '../../api';
import Dropdown from '../elements/Dropdown/Dropdown';

const renderers = [...vanillaRenderers, ToggleSwitchControl];

interface IProps {
  integration: IActiveIntegration;
  source: IIntegrationSource;
  userActions: IUserAction[];
  onSyncButtonClick: (integration: IActiveIntegration, startDate: string) => void;
  onDisconnectLinkClick: (integration: IActiveIntegration) => void;
}

const EnabledIntegrationConnector = ({
  userActions,
  integration,
  source,
  onDisconnectLinkClick,
}: IProps) => {
  const [data, setData] = useState();
  const [localIntegration, setLocalIntegration] = useState(integration);
  const [isSaveAvailable, setIsSaveAvailable] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(true);
  const [selectedAction, setSelectedAction] = useState<IUserAction | null>(null);

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);

  const onSelectedActionChange = (type: string) => {
    const action = userActions.find((action) => action.type === type);
    setSelectedAction(action || null);
  };

  const onUpdateData = (state: Pick<JsonFormsCore, 'data' | 'errors'>) => {
    if (!localIntegration?.config || !source || !source.schema) return;
    const schemaKeys = Object.keys(source.schema.jsonSchema.properties);
    const dataUpdated = Object.keys(localIntegration.config)
      .filter((key) => schemaKeys.includes(key))
      .some((key) => state.data[key] !== (localIntegration.config as any)[key]);
    if (dataUpdated) {
      setIsSaveAvailable(!state.errors?.length);
    } else {
      setIsSaveAvailable(false);
    }
    setData(state.data);
  };

  const onUpdateIntegration = async () => {
    setIsSaveAvailable(false);
    try {
      const response = await API.action.updateIntegration(localIntegration.id, data);
      setLocalIntegration(response);
    } catch (err) {
      console.log(err);
    }
  };

  const withoutSync =
    !integration?.config?.ongoingSync &&
    integration?.status === ActiveIntegrationStatusEnum.NEEDS_CONFIG;

  const toggleClassName = dropdownVisible ? 'visible' : 'hidden';

  useEffect(() => {
    if (!localIntegration || !source || !source.schema) return;
    const schemaKeys = Object.keys(source.schema.jsonSchema.properties);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updatedData: any = {};

    Object.keys(localIntegration.config).forEach((key) => {
      if (schemaKeys.includes(key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updatedData[key] = (localIntegration.config as any)[key];
      }
    });
    setData(updatedData);
  }, [localIntegration.config, source.schema]);

  useEffect(() => {
    setLocalIntegration(integration);
  }, [integration]);

  if (!localIntegration || !source) return null;

  return (
    <div className={`tpc-configure`}>
      <hr className={'tpc-configure-hr'} />
      <p className="tpc-source-account-title">
        <img
          className={`expand-toggle ${toggleClassName}`}
          alt={'Whoops!'}
          src={dropdown_toggle}
          onClick={() => toggleDropdown()}
          style={{ opacity: 0.7 }}
        />
        {localIntegration.entities[1].name || ''}
      </p>
      <hr className={'tpc-configure-hr'} />
      <div className={`tpc-configure-content tpc-${toggleClassName}`}>
        {withoutSync && (
          <div className="tpc-settings-row">
            <label className="tpc-configure-heading variant-green">
              Good job connecting your account, now let&apos;s run your first sync below to get
              started!
            </label>
          </div>
        )}
        <div className="tpc-settings-container">
          <div>
            <div className="tpc-settings-section">
              <div className="tpc-settings-row">
                {source.schema ? (
                  <JsonForms
                    schema={source.schema.jsonSchema}
                    uischema={source.schema.uiSchema}
                    data={data}
                    renderers={renderers}
                    cells={vanillaCells}
                    onChange={onUpdateData}
                  />
                ) : null}
              </div>
              <div className="tpc-actions">
                {userActions.length ? (
                  <Dropdown
                    placeholder="Select Action"
                    options={userActions.map((action) => ({
                      label: action.title,
                      value: action.type,
                    }))}
                    onChange={(e) => onSelectedActionChange(e as string)}
                    value=""
                  />
                ) : null}
              </div>
            </div>
            <div className="tpc-settings-actions">
              {/* Commented out till we implement JSONForm for settings */}
              <button
                className={`fb-primary ${!isSaveAvailable ? 'disabled' : ''} small`}
                disabled={!isSaveAvailable}
                onClick={onUpdateIntegration}
              >
                Save
              </button>
              <span />
              <button
                className="fb-primary outlined danger small"
                onClick={() => onDisconnectLinkClick(integration)}
              >
                Disconnect
              </button>
            </div>
          </div>
        </div>
      </div>
      {selectedAction ? (
        <ExecuteUserAction
          action={selectedAction}
          onCancel={() => onSelectedActionChange('')}
          integrationId={localIntegration.id}
          appName={source.name}
        />
      ) : null}
    </div>
  );
};

export default EnabledIntegrationConnector;
