import React from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import './assets/css/main.css';
import Test from './__test__';
import View from './view';
import Landing from './pages/landing';
import Configure from './pages/configure';
import Redirect from './pages/redirect';

export default function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/config" element={<Configure />} />
          <Route path="/redirect" element={<Redirect />} />
          <Route path="/test" element={<Test.ParentWrapper />} />
          <Route path="/view" element={<View />} />
        </Routes>
      </Router>
    </>
  );
}
