import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import API from '../../api/api';
import sync_graphic from '../../assets/media/sync_graphic.png';
import close_icon from '../../assets/media/close-2.svg';
import { updateFlashMessage } from '../../../actions/flashMessage';

const SUPPORT_LINK = 'https://support.freshbooks.com/hc/en-us';

interface IActionInProgressProps {
  integrationId: string;
  completeSync: () => void;
  closePopup: () => void;
}

const ActionInProgress = ({ integrationId, completeSync, closePopup }: IActionInProgressProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const integrationStatus = await API.action.getIntegrationStatus(integrationId);

        if (['PROCESSING', 'NEEDS_CONFIG'].includes(integrationStatus.status)) {
          setTimeout(checkStatus, 10000);
          return;
        }

        if (integrationStatus.status === 'ENABLED') {
          completeSync();
          return;
        }

        if (['ERROR', undefined].includes(integrationStatus.status)) {
          // Close the pop up and dispatch the flash error message action
          const text = `We're having <strong>trouble</strong> syncing your ${integrationStatus.type} account information. Please let us know so we can help you. <a href=${SUPPORT_LINK} target='_blank'>Contact Support</a>`;
          const type = integrationStatus.type;
          const message = {
            text: text,
            active: true,
            type,
          };

          closePopup();
          dispatch(updateFlashMessage(message));
          return;
        }
      } catch (error) {
        // throw new Error(error);
      }
    };

    setTimeout(checkStatus, 10000);
  }, [integrationId]);

  return (
    <>
      <div className={'popup'}>
        <div className={'await-sync dialogue md popup-main'}>
          <img src={close_icon} className="close" onClick={closePopup} />
          <h2 className={'variant-blue x-light'}>Data Syncing to FreshBooks</h2>
          <p className={'fb-copy'}>Hang tight, this could take up to 5 minutes</p>
          <br />
          <br />
          <img className={'spinner'} alt={'Whoops!'} src={sync_graphic} />
        </div>
      </div>
    </>
  );
};

export default ActionInProgress;
