import React, { useState } from 'react';
import { IAppState, IUserAction } from '../../../types/interfaces';
import { JsonForms } from '@jsonforms/react';
import { vanillaCells, vanillaRenderers } from '@jsonforms/vanilla-renderers';
import { StartDateControl } from '../controls/StartDateControl';
import { JsonFormsCore } from '@jsonforms/core';
import ActionInProgress from './action_in_progress';
import close_icon from '../../assets/media/close-2.svg';
import { getLocalAppState, setLocalAppState } from '../../utils/stateUtils';
import API from '../../api';
import SyncComplete from './sync_complete';

interface IExecuteUserActionProps {
  action: IUserAction;
  integrationId: string;
  appName: string;
  onCancel: () => void;
}

const renderers = [...vanillaRenderers, StartDateControl];

const ExecuteUserAction = ({
  action,
  onCancel,
  integrationId,
  appName,
}: IExecuteUserActionProps) => {
  const [data, setData] = useState({});
  const [isSaveAvailable, setIsSaveAvailable] = useState(false);
  const [screen, setScreen] = useState<'execute' | 'completed' | 'progress'>('execute');

  const onSubmit = async () => {
    setIsSaveAvailable(false);
    await API.action.executeUserAction({
      integrationId,
      actionId: action.type,
      data,
    });
    setScreen('progress');
  };

  const onUpdateData = (state: Pick<JsonFormsCore, 'data' | 'errors'>) => {
    setIsSaveAvailable(!state.errors?.length);
    setData(state.data);
  };

  const onSyncCompleted = async () => {
    const appState = await getLocalAppState();
    const newState = { ...appState } as IAppState;
    if (newState?.freshbooks?.entity?._id) {
      newState.activeIntegrations = await API.action.getActiveIntegrations(
        newState.freshbooks.entity?._id,
      );

      await setLocalAppState(newState);
    }
    setScreen('completed');
  };

  if (screen === 'progress') {
    return (
      <ActionInProgress
        closePopup={onCancel}
        integrationId={integrationId}
        completeSync={onSyncCompleted}
      />
    );
  }

  if (screen === 'completed') {
    return (
      <SyncComplete
        closePopup={onCancel}
        integrationId={integrationId}
        appName={appName}
        actionId={action.type}
      />
    );
  }

  return (
    <>
      <div className={'popup'}>
        <div className={'dialogue await-sync sm popup-main'}>
          <img src={close_icon} className="close" onClick={onCancel} />
          <h2 className={'variant-blue x-light mb-4'}> {action.title} </h2>

          <p className={'fb-copy mb-4'}>
            <JsonForms
              schema={action.schema.jsonSchema}
              uischema={action.schema.uiSchema}
              data={data}
              renderers={renderers}
              cells={vanillaCells}
              onChange={onUpdateData}
            />
          </p>
          <div className={'disconnect-action'}>
            <button onClick={onCancel} className={'fb-cancel clamped'}>
              Cancel
            </button>
            <button
              // onClick={confirmDisconnect}
              disabled={!isSaveAvailable}
              onClick={onSubmit}
              className={`fb-primary ${!isSaveAvailable ? 'disabled' : ''} clamped`}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExecuteUserAction;
