class Entity {
  static types = {
    freshbooks: 'Freshbooks',
    etsy: 'Etsy',
    stripe: 'Stripe',
    square: 'Square',
  };

  constructor(data) {
    if (data === null) throw new Error(`Cannot create entity on null object`);
    this.getId = () => data.id;
    this.getType = () => data.type;

    if ('type' in data) {
      switch (this.getType()) {
        case Entity.types.etsy:
          this.getAccountId = () => data.externalId;
          break;
        case Entity.types.freshbooks:
        default:
          this.getAccountId = () => data.exeternalId;
          break;
      }
    } else {
      this.getAccountId = () => null;
    }
  }
}

export default Entity;
