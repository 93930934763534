import React from 'react';
import dropdown_toggle from '../../assets/media/carat.png';
import coming_soon from '../../assets/media/Coming Soon@3x.png';
import { IIntegrationSource } from '../../../types/interfaces';

interface IDisabledConnectorProps {
  toggleDropdown: () => void;
  logo: React.ReactNode;
  source: IIntegrationSource;
}

const DisabledConnector = ({ toggleDropdown, logo, source }: IDisabledConnectorProps) => {
  return (
    <>
      <img
        className={'expand-toggle'}
        alt={'Whoops!'}
        src={dropdown_toggle}
        onClick={() => toggleDropdown()}
        style={{ visibility: 'hidden' }}
      />
      {logo}
      <div className="tpc-copy">
        <h4 className="tpc-source-title"> {source.name} </h4>
        <span className="tpc-source-description">{source.description}</span>
      </div>
      <div className="tpc-action">
        <img className={'tpc-coming-soon-image'} src={coming_soon} alt="Coming Soon"></img>
      </div>
    </>
  );
};

export default DisabledConnector;
