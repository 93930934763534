import React from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { ControlProps, rankWith, scopeEndsWith } from '@jsonforms/core';
import { withVanillaControlProps } from '@jsonforms/vanilla-renderers';

export const StartDateComponent = ({
  data,
  handleChange,
  label,
  schema,
  path,
  rootSchema,
}: ControlProps) => {
  console.log({
    data,
    handleChange,
    label,
    schema,
    path,
    rootSchema,
  });
  return (
    <div>
      <label style={{ marginRight: 10 }}>{label}</label>
      <input
        className="tpc-input"
        type="date"
        value={data || ''}
        onChange={(e) => handleChange(path, e.target.value)}
      />
    </div>
  );
};

export const StartDateControl = {
  tester: rankWith(2000, scopeEndsWith('startDate')),
  renderer: withVanillaControlProps(withJsonFormsControlProps(StartDateComponent)),
};
