class ApiError {
  static types = {
    requestError: 'Request Error',
    authorizationError: 'Authorization Error',
  };

  static RequestError(message, data) {
    return new Error(
      `${ApiError.types.requestError} -- ${message} -- failed with [\r\n ${data} \r\n]`,
    );
  }

  static AuthorizationError(message, data) {
    return new Error(
      `${ApiError.types.authorizationError} -- ${message} -- failed with [\r\n ${data} \r\n]`,
    );
  }
}

export default ApiError;
