import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateFlashMessage } from '../../../actions/flashMessage';
import { IActiveIntegration, IEntity, IIntegrationSource } from '../../../types/interfaces';
import DisabledConnector from './disabled_connector';
import EnabledConnector from './enabled_connector';
import NewConnector from './new_connector';
import API from '../../api';

async function getSourceLogo(source: string) {
  return (await import(`../../assets/media/logos/logo-${source.toLowerCase()}.png`)).default;
}

async function getSourceLogoSet(source: string) {
  const results = await Promise.all(
    ['2x', '3x'].map(async (size) => {
      const imgData = await import(
        `../../assets/media/logos/logo-${source.toLowerCase()}@${size}.png`
      );
      return `${imgData.default} ${size}`;
    }),
  );

  return results.join(', ');
}

interface IThirdPartyConnectorProps {
  source: IIntegrationSource;
  isConnected: boolean;
  isActive: boolean;
  integrations: IActiveIntegration[] | null | undefined;
  hasMany: boolean;
  entity?: IEntity;
  startSync: (data: {
    startDate: string;
    integrationId: string;
    appName: string;
    status: string;
  }) => void;
  showDisconnectIntegration: ({
    integrationId,
    sourceName,
  }: {
    integrationId: string;
    sourceName: string;
  }) => void;
}

const ThirdPartyConnector = ({
  integrations: integrationData,
  source,
  isActive,
  entity,
  startSync,
  showDisconnectIntegration,
  hasMany,
}: IThirdPartyConnectorProps) => {
  const [dropdownVisible, setDropdownVisible] = useState(true);
  const [logo, setLogo] = useState('');
  const [logoSrcSet, setLogoSrcSet] = useState('');

  const dispatch = useDispatch();

  const LogoEl = <img className="tpc-logo" alt={'Whoops!'} src={logo} srcSet={logoSrcSet} />;

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);

  const onConnectAttempt = async (source: IIntegrationSource) => {
    const response = await API.action.getAuthorizeUri({
      integrationType: source.name.toLowerCase(),
      redirect_uri: encodeURIComponent(API.constants.selfURI + '/redirect'),
    });
    if (typeof response === 'string' || (!response.redirect_uri && !response.url)) {
      console.error(API.error.AuthorizationError(`${source.name} Error`, response));
    } else {
      const redirectURL = response.redirect_uri || response.url;
      if (redirectURL) {
        window.location.href = redirectURL;
      }
    }
  };

  const runSync = async (
    syncStartDetails = {
      startDate: '',
      integrationId: '',
      appName: '',
      status: '',
    },
  ) => {
    startSync(syncStartDetails);
    const message = {
      text: '',
      active: false,
      type: '',
    };
    dispatch(updateFlashMessage(message));
  };

  const getCurrentRenderComponent = () => {
    if (!source.enabled) {
      return <DisabledConnector toggleDropdown={toggleDropdown} logo={LogoEl} source={source} />;
    }
    if (isActive && integrationData?.length) {
      return (
        <EnabledConnector
          logo={LogoEl}
          dropdownVisible={dropdownVisible}
          toggleDropdown={toggleDropdown}
          integrations={integrationData}
          runSync={runSync}
          showDisconnectIntegration={showDisconnectIntegration}
          source={source}
        />
      );
    }

    if (!entity) {
      return (
        <NewConnector
          source={source}
          logo={LogoEl}
          toggleDropdown={toggleDropdown}
          onConnectAttempt={() => onConnectAttempt(source)}
        />
      );
    }

    return (
      <NewConnector
        source={source}
        logo={LogoEl}
        toggleDropdown={toggleDropdown}
        onConnectAttempt={() => onConnectAttempt(source)}
      />
    );
  };

  useEffect(() => {
    async function load() {
      const logo = await getSourceLogo(source.name);
      setLogo(logo);
      const srcSet = await getSourceLogoSet(source.name);
      setLogoSrcSet(srcSet);
    }

    load();
  }, [source.name]);

  return (
    <>
      <div className={'tpc-container'}>{getCurrentRenderComponent()}</div>
      {hasMany && dropdownVisible && (
        <div className="tpc-new-account">
          <button className="link" onClick={() => onConnectAttempt(source)}>
            Connect to a New Account
          </button>
        </div>
      )}
      <hr className={'tpc-hr'} />
    </>
  );
};

export default ThirdPartyConnector;
