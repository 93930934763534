import React from 'react';
import dropdown_toggle from '../../assets/media/carat.png';
import { IIntegrationSource } from '../../../types/interfaces';

interface INewConnectorProps {
  toggleDropdown: () => void;
  logo: React.ReactNode;
  source: IIntegrationSource;
  onConnectAttempt: () => void;
}

const NewConnector = ({ toggleDropdown, logo, source, onConnectAttempt }: INewConnectorProps) => {
  return (
    <>
      <img
        className={'expand-toggle'}
        style={{ visibility: 'hidden' }}
        alt={'Whoops!'}
        src={dropdown_toggle}
        onClick={() => toggleDropdown()}
      />
      {logo}
      <div className="tpc-copy">
        <h4 className="tpc-source-title"> {source.name} </h4>
        <span className="tpc-source-description">{source.description}</span>
      </div>

      <div className="tpc-action">
        <button className="fb-primary xx-light" onClick={() => onConnectAttempt()}>
          Connect
        </button>
      </div>
    </>
  );
};

export default NewConnector;
