import React from 'react';

const IntegrationSourceParserViewer = () => {
  return (
    <>
      <h3> Integration Source Parser Viewer </h3>
      <hr />
      <span> Raw JSON </span>
      <br />
      <button> PARSE </button>
      <br />
      {/* <JSONPretty data={API.util.integrationSourceEG} /> */}
      {/* <ThirdPartyConnector source={API.util.integrationSourceEG.integrationSources[0]} /> */}
    </>
  );
};

export default IntegrationSourceParserViewer;
