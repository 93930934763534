import React, { useEffect } from 'react';
import qString from 'query-string';
import he from 'he';
import API from '../api/api';
import { updateFlashMessage } from '../../actions/flashMessage';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  IAppState,
  IAuthorizationCredentials,
  IFlashMessage,
  IFreshbooksState,
  IRedirectParams,
} from '../../types/interfaces';
import { getLocalAppState, setLocalAppState } from '../utils/stateUtils';

const SUPPORT_LINK = 'https://support.freshbooks.com/hc/en-us';

const INIT_FRESHBOOK_OBJECT: IFreshbooksState = {
  businessMemberships: [],
  userInstance: null,
  currentBusiness: null,
  credentialId: null,
  entity: null,
};

function Redirect() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const currentApp: string = JSON.parse(he.decode(query.get('state') ?? '{app: ""}')).app;

  const throwErrorAndRedirectToMain = async (message: IFlashMessage) => {
    console.log(`Throwing an error with ${JSON.stringify(message)}`);
    const lss = localStorage.getItem('lss');

    const stateObject = lss ? JSON.parse(lss) : { freshbooks: INIT_FRESHBOOK_OBJECT };
    dispatch(updateFlashMessage(message));
    const newStateObject = { ...stateObject };
    newStateObject.flashMessage = message;
    await setLocalAppState(newStateObject);

    console.log(`Supposedly updated the state with the error message object`);
    navigate('/');
  };

  useEffect(() => {
    async function exec() {
      console.log({ currentApp });
      const params = qString.parse(window.location.search) as unknown as IRedirectParams;

      let stateObject = getLocalAppState();
      if (!stateObject) {
        await setLocalAppState({ freshbooks: INIT_FRESHBOOK_OBJECT });
        stateObject = getLocalAppState() as IAppState;
      }

      const authorizationCredentials: IAuthorizationCredentials = {
        type: currentApp,
        token: params.oauth_token || undefined,
        verifier: params.oauth_verifier || undefined,
        ...params,
      };

      const authorizeResponse = await API.action.authorizeCallback({
        authorizationCredentials: authorizationCredentials,
      });

      const newStateObject = { ...stateObject };

      if (currentApp === 'freshbooks') {
        newStateObject.freshbooks.credentialId = authorizeResponse.credentialId;
        newStateObject.freshbooks.businessMemberships = authorizeResponse.business_memberships;
        newStateObject.freshbooks.userInstance = authorizeResponse.user_instance;
      } else if (stateObject.freshbooks.entity) {
        // SearchOrCreateEntity should only happen if we do not receive entity_id from the auth response, which currently should never happen
        // const newEntityResponse = await API.action.searchOrCreateEntity({
        //   integrationType: currentApp,
        //   entityDetails: {
        //     credential_id: authorizeResponse.credential_id,
        //   },
        // });
        // TODO ONLY WHEN THE USE CASE COMES UP... we need a UI flow for whenever a user authenticates and needs to select
        //  from entityOptions, which likely belongs outside of this redirect flow? Or can be in here, but components pulled in.

        try {
          const newIntegrationResponse = await API.action.searchOrCreateIntegration(
            currentApp,
            stateObject.freshbooks.entity._id,
            authorizeResponse.entity_id,
          );

          if (!newIntegrationResponse) {
            const text = `There was a problem creating an integration with this ${currentApp} account. <strong>Most likely this account is already connected to another FreshBooks Business.</strong> If you believe this is in error, please let us know so we can help you. <a href=${SUPPORT_LINK} target='_blank'>Contact Support</a>`;
            const type = currentApp;
            const message = {
              text: text,
              active: true,
              type,
            };
            await throwErrorAndRedirectToMain(message);
          }
        } catch (e) {
          console.log(e);
        }
      }

      // NEW STATE SAVING SECTION
      await setLocalAppState(newStateObject);

      // REDIRECT TO CONFIG SECTION
      navigate(`/config`);
    }

    exec();
  }, [currentApp]);

  return (
    <div className="text-center">
      <h2 className="x-light" style={{ paddingTop: '3em' }}>
        Please wait while we redirect you.
      </h2>
    </div>
  );
}

export default Redirect;
