/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IRedirectParams {
  oauth_token?: string;
  oauth_verifier?: string;
  code?: string;
  error?: string;
  error_description?: string;
  state: string;
}

export interface IAuthorizationCredentials {
  type: string;
  state?: string;
  code?: string;
  token?: string;
  verifier?: string;
}

export interface IEntityDetails {
  credential_id: string;
  account_id?: string | null;
}

export interface IFlashMessage {
  type: string;
  active: boolean;
  text: string;
}

export interface IBusinessMembership {
  id: string;
  name: string;
}

export interface IEntity {
  _id: string;

  user: string;
  externalId: string;
  credential: string;

  updatedAt: Date;
  createdAt: Date;
}

export interface IUserInstance {
  freshbooks_authorized: boolean;
  freshbooks_user_id: number;
  freshbooks_user_name: string;
}

export interface IFreshbooksState {
  businessMemberships: IBusinessMembership[];
  credentialId?: string | null;
  currentBusiness?: IBusinessMembership | null;
  entity?: IEntity | null;
  userInstance?: IUserInstance | null;
}

// ActiveIntegration
export interface IActiveIntegrationMessages {
  errors: string[];
  warnings: string[];
  info: string[];
  logs: string[];
}

export interface IActiveIntegrationEntity {
  id: string;
  externalId: string;
  name?: string | null;
  __t: string;
}

// Not quite right, but close enough. Ultimately this should be more dynamically generated
//  and the results typed from the backend based on Action ID
export interface ISyncProcessRecord {
  results: {
    otherIncome: {
      transactions: number;
      amounts: { [key: string]: number };
    };
    expenses: {
      transactions: number;
      amounts: { [key: string]: number };
    };
  };
}

export interface IActiveIntegrationConfig {
  lastSyncEnd: Date;
  lastSyncStart: Date;
  lastSyncProcessId: string;
  ongoingSync: boolean;
  type: string;
}

export enum ActiveIntegrationStatusEnum {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  NEEDS_CONFIG = 'NEEDS_CONFIG',
}

export interface IActiveIntegration {
  id: string;
  status: ActiveIntegrationStatusEnum;
  version: string;
  messages: IActiveIntegrationMessages;
  entities: IActiveIntegrationEntity[];
  config: IActiveIntegrationConfig;
}

// End ActiveIntegration

// IntegrationSources
export interface IIntegrationSourcesEntitiesOption {
  type: string;
  requiresNewEntity: boolean;
  isMany: boolean;
  hasUserConfig: boolean;
  display: {
    name: string;
    description: string;
    icon: string;
    detailsUrl: string;
  };
}

export interface IIntegrationSourcesEntities {
  primary: string;
  authorized: any[];
  options: IIntegrationSourcesEntitiesOption[];
}

export interface IIntegrationSource {
  name: string;
  description: string;
  enabled: boolean;
  requiresSelection: boolean;
  settings?: any[] | null;
  schema: {
    jsonSchema: any;
    uiSchema: any;
  };
  userActions: { [key: string]: IUserAction };
}

export interface IIntegrationSources {
  entities: IIntegrationSourcesEntities;
  integrations: IActiveIntegration[];
  integrationSources: IIntegrationSource[];
}

export interface IUserAction {
  title: string;
  type: string;
  description: string;
  label: string;
  schema: {
    jsonSchema: any;
    uiSchema: any;
  };
}

// End IntegrationSources
export interface IIntegrationById {
  id: string;
  status: ActiveIntegrationStatusEnum;
  entities: string[];
  config: IActiveIntegrationConfig;
}

export interface IIntegrationStatus {
  type: string;
  status: string;
}

export interface ISyncStatus {
  type: string;
  status: string;
}

export interface IAppState {
  freshbooks: IFreshbooksState;
  // To be defined
  activeIntegrations?: IActiveIntegration[];
  integrationSources?: IIntegrationSources | null;
  flashMessage?: IFlashMessage | null;
  popupIntegrationId?: string | null;
  popupSourceName?: string | null;
  popupStatus?: string | null;
  popupAppName?: string | null;
  screen?: string | null;
  showPopup?: boolean;
  thirdPartyEntities?: [];
}
