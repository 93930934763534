import React, { useState } from 'react';
import ThirdPartyConnector from '../third_party_connector';
import freshbooks_logo_full from '../../assets/media/fb_logo_full.png';
import {
  IActiveIntegration,
  IAppState,
  IBusinessMembership,
  IEntity,
  IIntegrationSource,
  IIntegrationSources,
} from '../../../types/interfaces';
import SettingsWheel from '../../assets/media/settings_wheel.png';
import { CONFIG_TITLE, CONFIG_SUB_HEADER } from '../../utils/constants';

interface IOtherIncomeMainProps {
  activeIntegrations: IActiveIntegration[];
  integrationSources: IIntegrationSources;
  primaryEntity: IEntity;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  thirdPartyEntities: any[];
  //   registerThirdPartyEntity,
  startSync: () => void;
  showDisconnectIntegration: (data: { integrationId: string; sourceName: string }) => void;
  setAndSaveState: (data: IAppState) => void;
  currentBusiness: IBusinessMembership;
  onSwitchBusiness: () => void;
}

const getIntegrationOrSource = ({
  source,
  activeIntegrations,
  thirdPartyEntities,
}: {
  source: IIntegrationSource;
  activeIntegrations: IActiveIntegration[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  thirdPartyEntities: any[];
}) => {
  const integrations = activeIntegrations?.length
    ? activeIntegrations
        .slice()
        .reverse()
        .filter(
          (integration) => integration.config.type.toLowerCase() === source.name.toLowerCase(),
        )
    : null;
  const isActive = !!integrations?.length;

  const entity = thirdPartyEntities?.length
    ? thirdPartyEntities
        .slice()
        .reverse()
        .find((entity) => entity.type.toLowerCase() === source.name.toLowerCase())
    : null;
  const isConnected = !!entity;

  return {
    integrations,
    isActive,
    entity,
    isConnected,
  };
};

const Dashboard = ({
  activeIntegrations,
  integrationSources,
  thirdPartyEntities,
  startSync,
  showDisconnectIntegration,
  currentBusiness,
  onSwitchBusiness,
}: IOtherIncomeMainProps) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const onToggleDropdown = () => setDropdownVisible(!dropdownVisible);
  const getConfigTitle = () => {
    const clientAppType = process.env.REACT_APP_CLIENT_APP_TYPE as keyof typeof CONFIG_TITLE;
    return CONFIG_TITLE[clientAppType];
  };

  const getConfigSubHeader = () => {
    const clientAppType = process.env.REACT_APP_CLIENT_APP_TYPE as keyof typeof CONFIG_SUB_HEADER;
    return CONFIG_SUB_HEADER[clientAppType];
  };
  return (
    <>
      <div className={'dialogue lg relative'}>
        <div className="settings-container">
          <button className="settings-btn" onClick={onToggleDropdown}>
            <img src={SettingsWheel} alt="Settings" />
          </button>
          <div
            className="settings-dropdown dialogue sm"
            style={{ display: dropdownVisible ? 'block' : 'none' }}
          >
            <h4 className="variant-blue x-light">Connection Settings</h4>
            <p>
              <small className="x-light variant-blue">FreshBooks Business</small>
              <br />
              {currentBusiness.name}
            </p>
            <button className="settings-switch-business" onClick={onSwitchBusiness}>
              Switch Business
            </button>
          </div>
        </div>
        {dropdownVisible && (
          <div className="settings-dropdown-capture" onClick={onToggleDropdown}></div>
        )}
        <img className={'fb-logo-full'} alt={'Whoops!'} src={freshbooks_logo_full} />

        <h2 className={'variant-blue x-light config-title'}>{getConfigTitle()}</h2>
        <p className={'fb-copy config-sub-header'}>{getConfigSubHeader()}</p>
        <br />

        <hr className={'tpc-hr'} />
        {integrationSources.integrationSources?.map((s, n) => {
          const { integrations, isActive, entity, isConnected } = getIntegrationOrSource({
            source: s,
            activeIntegrations,
            thirdPartyEntities,
          });

          const integrationSourceEntity = integrationSources.entities.options.find(
            (ent) => ent.type === integrations?.[0]?.config.type,
          );
          return (
            <ThirdPartyConnector
              key={(n * 14.495) ** 3}
              source={s}
              isActive={isActive}
              isConnected={isConnected}
              integrations={integrations}
              hasMany={!!integrationSourceEntity?.isMany}
              entity={entity}
              //   registerThirdPartyEntity={registerThirdPartyEntity}
              startSync={startSync}
              showDisconnectIntegration={showDisconnectIntegration}
            />
          );
        })}
      </div>
    </>
  );
};

export default Dashboard;
