import { combineReducers } from 'redux';
import { flashMessage } from './flashMessage';

// this function combines all the application reducers into one for redux to use
const appReducer = combineReducers({
  // flash messages reducer
  flashMessage,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;

    sessionStorage.clear();
  }
  return appReducer(state, action);
};

export default rootReducer;
