import React from 'react';

interface IConfirmDisconnectProps {
  sourceName: string;
  confirmDisconnect: () => void;
  cancelDisconnect: () => void;
}

const ConfirmDisconnect = ({
  sourceName,
  cancelDisconnect,
  confirmDisconnect,
}: IConfirmDisconnectProps) => {
  return (
    <>
      <div className={'popup'}>
        <div className={'dialogue sm disconnect popup-main'}>
          <div className={'disconnect-heading'}>
            <h3 className={'x-light disconnect'}> Confirm </h3>
          </div>

          <p className={'fb-copy disconnect'}>
            Are you sure you want to disconnect {sourceName}? Your {sourceName} data will no longer
            be synced to FreshBooks.
          </p>
          <hr />
          <div className={'disconnect-action'}>
            <button onClick={cancelDisconnect} className={'fb-cancel clamped'}>
              Cancel
            </button>
            <button onClick={confirmDisconnect} className={'fb-primary clamped'}>
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmDisconnect;
