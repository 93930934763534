import React, { useEffect, useState } from 'react';
import API from '../api/api';
import Dialogues from '../components/dialogues';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateFlashMessage } from '../../actions/flashMessage';
import { IAppState, IBusinessMembership } from '../../types/interfaces';
import {
  CONFIGURE_NEXT_ACTION,
  CONFIGURE_SCREENS,
  confirmViableAppState,
  getInitialState,
  getLocalAppState,
  setLocalAppState,
} from '../utils/stateUtils';

function getPreviouselySelectedBusinessNextAction(
  previousBusinessIsMember: IBusinessMembership | null | undefined,
  existingEntityIsBusiness: boolean | null,
) {
  let nextAction = '';

  if (!previousBusinessIsMember) {
    nextAction = CONFIGURE_NEXT_ACTION.clearAndSelect;
  }

  if (previousBusinessIsMember && existingEntityIsBusiness) {
    nextAction = CONFIGURE_NEXT_ACTION.refreshAndLoad;
  }

  if (previousBusinessIsMember && !existingEntityIsBusiness) {
    nextAction = CONFIGURE_NEXT_ACTION.shouldRegister;
  }

  return nextAction;
}

function getAccountNextAction(
  accountIsMember: IBusinessMembership | null | undefined,
  accountAndBusinessMatch: boolean | null,
  existingEntityIsAccount: boolean | null,
) {
  let nextAction = '';

  if (!accountIsMember) {
    nextAction = CONFIGURE_NEXT_ACTION.clearAndSelect;
  }

  if (accountIsMember && accountAndBusinessMatch && existingEntityIsAccount) {
    nextAction = CONFIGURE_NEXT_ACTION.refreshAndLoad;
  }

  if (
    (accountIsMember && !accountAndBusinessMatch) ||
    (accountIsMember && accountAndBusinessMatch && !existingEntityIsAccount)
  ) {
    nextAction = CONFIGURE_NEXT_ACTION.shouldRegister;
  }

  return nextAction;
}

function Configure() {
  const [appState, setAppState] = useState<IAppState>(getInitialState());
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const persistState = async (newState: IAppState) => {
    setAppState(newState);
    await setLocalAppState(newState);
  };

  const setScreen = async (screen: string) => {
    const newState = { ...appState };
    newState.screen = screen;
    await persistState(newState);
  };

  const onSwitchBusiness = async () => {
    await setScreen(CONFIGURE_SCREENS.selectFreshbooksBusiness);
  };

  const registerFreshbooksBusinessSelection = async (
    freshbooksBusinessSelection: IBusinessMembership,
  ) => {
    // Set loading screen as we wait for API stuff

    const newState = { ...appState };
    if (newState.freshbooks.credentialId) {
      await setScreen(CONFIGURE_SCREENS.loading);
      console.log('State from within registerFreshbooksBusinessSelection', newState);
      newState.freshbooks.currentBusiness = freshbooksBusinessSelection;

      newState.freshbooks.entity = await API.action.searchOrCreateEntity({
        integrationType: 'freshbooks',
        entityDetails: {
          account_id: freshbooksBusinessSelection.id,
          credential_id: newState.freshbooks.credentialId,
        },
      });

      if (newState.freshbooks.entity?._id) {
        newState.activeIntegrations = await API.action.getActiveIntegrations(
          newState.freshbooks.entity?._id,
        );
      }

      newState.screen = CONFIGURE_SCREENS.configureIntegrations;

      await persistState({ ...newState });
    }

    // await this.refreshActiveIntegrations();
  };

  const togglePopup = async () => {
    const newState = { ...appState };
    newState.showPopup = !newState.showPopup;
    await persistState(newState);
  };

  const startSync = async (
    syncDetails = { integrationId: '', startDate: '', appName: '', status: '' },
  ) => {
    console.log(
      `startSync for ${syncDetails.appName} called with ${syncDetails.integrationId} and ${syncDetails.startDate}`,
    );
    try {
      if (syncDetails.status === 'New') {
        await API.action.enableOngoingSync({
          integrationId: syncDetails.integrationId,
        });
      }
      await API.action.startSync(syncDetails);
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      throw new Error(error as any);
    }

    const newState = { ...appState };
    newState.popupIntegrationId = syncDetails.integrationId;
    // TODO Intent is to use this for certain display. Not sure if this is the right way to do this, though. TBD
    newState.popupStatus = `startSync`;
    newState.popupAppName = syncDetails.appName;
    newState.showPopup = true;
    await persistState(newState);
  };

  const showDisconnectIntegration = async (
    integrationDetails = { integrationId: '', sourceName: '' },
  ) => {
    console.log(
      `Integration of ID ${integrationDetails.integrationId} connected to ${integrationDetails.sourceName} has been requested to disconnect, `,
    );
    const newState = { ...appState };

    newState.popupIntegrationId = integrationDetails.integrationId;
    newState.popupStatus = `confirmDisconnect`;
    newState.popupSourceName = integrationDetails.sourceName ?? '';
    newState.showPopup = true;
    await persistState(newState);
  };

  const confirmDisconnect = async () => {
    if (appState.popupIntegrationId) {
      // call api to delete the integration
      const res = await API.action.deleteIntegration(appState.popupIntegrationId);
      console.log(res);

      const newState = { ...appState };
      newState.showPopup = false;
      newState.screen = CONFIGURE_SCREENS.loading;
      await persistState(newState);

      if (newState.freshbooks.entity?._id) {
        newState.activeIntegrations = await API.action.getActiveIntegrations(
          newState.freshbooks.entity?._id,
        );
      }
      newState.screen = CONFIGURE_SCREENS.configureIntegrations;

      await persistState(newState);
    }
  };

  const renderCurrentScreen = () => {
    switch (appState.screen) {
      case CONFIGURE_SCREENS.selectFreshbooksBusiness:
        return (
          <>
            <Dialogues.SelectBusiness
              options={appState.freshbooks.businessMemberships}
              title="Select a Business"
              register={async (sel: IBusinessMembership) =>
                await registerFreshbooksBusinessSelection(sel)
              }
            />
          </>
        );
      case CONFIGURE_SCREENS.configureIntegrations:
        return (
          <>
            <Dialogues.Dashboard
              activeIntegrations={appState.activeIntegrations ?? []}
              integrationSources={appState.integrationSources!}
              currentBusiness={appState.freshbooks.currentBusiness ?? { id: '', name: '' }}
              //TODO primaryEntity may no longer need to be passed down, since we're building the integration up here in this component
              primaryEntity={appState.freshbooks.entity!}
              //TODO thirdPartyEntities may no longer need to be passed down, since we're building the integration up here in this component
              thirdPartyEntities={appState.thirdPartyEntities ?? []}
              // setParentState={this.hoistStateChange}
              startSync={startSync}
              showDisconnectIntegration={showDisconnectIntegration}
              setAndSaveState={persistState}
              onSwitchBusiness={onSwitchBusiness}
            />
          </>
        );
      default:
        return (
          <>
            <Dialogues.Loading />
          </>
        );
    }
  };

  useEffect(() => {
    async function exec() {
      /*
       * Upon loading we have to figure out if we need to ask for business selection
       * 1. If there's no selected business and no account ID stored, ask for selection
       * 2. If there's an account ID stored but it does not exist in businessMemberships, clear accountid and ask for selection
       * 3. If there's a selected business (and no accountid stored) but it does not exist in businessMemberships,
       *   clear selectedBusiness and ask for selection
       *
       * THEN, if we have a selected business, register it.
       *  Registering creates or finds the Entity given the selected Business and retrieves active integrations, then displays the Configure screen
       * */

      try {
        console.log(`Config page loaded!`);
        // Get the state, if it was stored
        const stateObject = getLocalAppState();
        const canContinue = confirmViableAppState(stateObject);

        if (!stateObject || !canContinue || !stateObject.freshbooks?.credentialId) {
          // CHECK: might need to clear local state
          return navigate('/');
        }
        if (stateObject.flashMessage) {
          dispatch(updateFlashMessage(stateObject.flashMessage));
          stateObject.flashMessage = null;
        }

        // Get all integrationSources and save to state every time we render
        stateObject.integrationSources = await API.action.getIntegrationSources();

        // FRESHBOOKS BUSINESS SELECTION DETERMINATION SECTION
        // Get the stored account ID (if any)
        const accountId = localStorage.getItem('accountid');
        // Get the available Business Memberships
        const businessMemberships = stateObject.freshbooks.businessMemberships;
        // Get the stored Current Business (if any)
        const previouslySelectedBusiness = stateObject.freshbooks.currentBusiness;
        // Get the FB entity (if present) freshBooksEntity.account_id
        const freshBooksEntity = stateObject.freshbooks.entity;

        // Prep some logical truthiness
        const accountAndBusinessMatch = accountId
          ? accountId === previouslySelectedBusiness?.id // CHECK: this was previousely  === previouslySelectedBusiness
          : null;
        const accountIsMember = accountId
          ? businessMemberships.find((member) => member.id === accountId)
          : null;
        const previousBusinessIsMember = previouslySelectedBusiness
          ? businessMemberships.find((member) => member.id === previouslySelectedBusiness.id)
          : null;
        const existingEntityIsAccount =
          freshBooksEntity && accountId
            ? accountId === freshBooksEntity.externalId /// CHECK: This was previousely account_id
            : null;
        const existingEntityIsBusiness =
          freshBooksEntity && previouslySelectedBusiness
            ? previouslySelectedBusiness.id === freshBooksEntity.externalId /// CHECK: this was previousely account_id
            : null;

        let businessToRegister;
        let nextAction = '';

        // This is some funky logic. Here's a photo of it: https://share.getcloudapp.com/jkuln1pw
        if (accountId) {
          businessToRegister = accountIsMember;
          nextAction = getAccountNextAction(
            accountIsMember,
            accountAndBusinessMatch,
            existingEntityIsAccount,
          );
        } else if (previouslySelectedBusiness) {
          businessToRegister = previousBusinessIsMember;
          nextAction = getPreviouselySelectedBusinessNextAction(
            previousBusinessIsMember,
            existingEntityIsBusiness,
          );
        } else {
          nextAction = CONFIGURE_NEXT_ACTION.showBusinessSelect;
        }

        if (nextAction === CONFIGURE_NEXT_ACTION.showBusinessSelect) {
          stateObject.screen = CONFIGURE_SCREENS.selectFreshbooksBusiness;
        }

        if (nextAction == CONFIGURE_NEXT_ACTION.shouldRegister && businessToRegister) {
          stateObject.freshbooks.currentBusiness = businessToRegister;
          stateObject.freshbooks.entity = await API.action.searchOrCreateEntity({
            integrationType: 'freshbooks',
            entityDetails: {
              account_id: businessToRegister.id,
              credential_id: stateObject.freshbooks.credentialId,
            },
          });
        }

        if (
          [CONFIGURE_NEXT_ACTION.refreshAndLoad, CONFIGURE_NEXT_ACTION.shouldRegister].includes(
            nextAction,
          ) &&
          stateObject.freshbooks.entity?._id
        ) {
          stateObject.activeIntegrations = await API.action.getActiveIntegrations(
            stateObject.freshbooks.entity?._id,
          );
          stateObject.screen = CONFIGURE_SCREENS.configureIntegrations;
        }

        if (nextAction === CONFIGURE_NEXT_ACTION.clearAndSelect) {
          localStorage.removeItem('accountid');
          stateObject.freshbooks.currentBusiness = null;
          stateObject.screen = CONFIGURE_SCREENS.selectFreshbooksBusiness;
        }

        // finally, persist state
        await persistState(stateObject);
      } catch (error) {
        console.error('Error during configuration:', error);
      }
    }

    exec();
  }, [dispatch]);

  return (
    <>
      <div id={'main'}>{renderCurrentScreen()}</div>
      {!appState.showPopup ? null : appState.popupStatus === 'confirmDisconnect' ? (
        <Dialogues.ConfirmDisconnect
          cancelDisconnect={() => togglePopup()}
          confirmDisconnect={confirmDisconnect}
          sourceName={appState.popupSourceName!}
        />
      ) : null}
    </>
  );
}

export default Configure;
