import React from 'react';
import dropdown_toggle from '../../assets/media/carat.png';
import { IActiveIntegration, IIntegrationSource } from '../../../types/interfaces';
import EnabledIntegrationConnector from './enabled_integration_connector';

interface IEnabledConnectorProps {
  source: IIntegrationSource;
  integrations: IActiveIntegration[];
  logo: React.ReactNode;
  dropdownVisible: boolean;
  toggleDropdown: () => void;
  runSync: (data: {
    startDate: string;
    integrationId: string;
    appName: string;
    status: string;
  }) => void;
  showDisconnectIntegration: (data: { integrationId: string; sourceName: string }) => void;
}

const EnabledConnector = ({
  source,
  integrations,
  toggleDropdown,
  dropdownVisible,
  logo,
  runSync,
  showDisconnectIntegration,
}: IEnabledConnectorProps) => {
  if (!integrations.length) return null;

  const onSyncButtonClick = (integration: IActiveIntegration, startDate: string) =>
    runSync({
      startDate: startDate,
      integrationId: integration.id,
      appName: source.name,
      status: integration?.status,
    });

  const onDisconnectLinkClick = (integration: IActiveIntegration) => {
    showDisconnectIntegration({
      integrationId: integration.id,
      sourceName: source.name,
    });
  };

  const toggleClassName = dropdownVisible ? 'visible' : 'hidden';

  return (
    <>
      <img
        className={`expand-toggle ${toggleClassName}`}
        alt={'Whoops!'}
        src={dropdown_toggle}
        onClick={() => toggleDropdown()}
      />

      {logo}

      <div className="tpc-copy">
        <h4 className="tpc-source-title"> {source.name} </h4>
        <span className="tpc-source-description">{source.description}</span>
      </div>

      <div className="tpc-action">
        <button className="fb-primary outlined disabled xx-light" disabled>
          Connected
        </button>
      </div>
      <div className={`tpc-${toggleClassName}`}>
        {integrations.map((integration) => (
          <EnabledIntegrationConnector
            key={integration.id}
            integration={integration}
            source={source}
            userActions={source.userActions ? Object.values(source.userActions) : []}
            onDisconnectLinkClick={onDisconnectLinkClick}
            onSyncButtonClick={onSyncButtonClick}
          />
        ))}
        <hr className="tpc-hr" style={{ marginLeft: 25 }} />
      </div>
    </>
  );
};

export default EnabledConnector;
