import Login from './login';
import Dashboard from './dashboard';
import ActionInProgress from './action_in_progress';
import SyncComplete from './sync_complete';
import ConfirmDisconnect from './confirm_disconnect';
import SelectBusiness from './select_business';
import Loading from './loading';

export default {
  Login,
  Dashboard,
  ActionInProgress,
  SyncComplete,
  ConfirmDisconnect,
  SelectBusiness,
  Loading,
};
