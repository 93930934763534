import React from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { ControlProps, rankWith, scopeEndsWith } from '@jsonforms/core';
import { withVanillaControlProps } from '@jsonforms/vanilla-renderers';

export const ToggleSwitchComponent = ({
  data,
  handleChange,
  label,
  schema,
  path,
  rootSchema,
}: ControlProps) => {
  console.log({
    data,
    handleChange,
    label,
    schema,
    path,
    rootSchema,
  });
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <label className="tpc-input-caps">{label}</label>
      <label className="form-switch">
        <input
          type="checkbox"
          checked={data || false}
          onChange={(e) => handleChange(path, e.target.checked)}
        />
        <i></i>
      </label>
    </div>
  );
};

export const ToggleSwitchControl = {
  tester: rankWith(2000, scopeEndsWith('ongoingSync')),
  renderer: withVanillaControlProps(withJsonFormsControlProps(ToggleSwitchComponent)),
};
