export default {
  integrationSources: [
    {
      name: 'Etsy' /* Name of integration source */,
      enabled: true /* Enabled/Disabled */,
      description:
        'Connect your Etsy account to give Income Importer access to your net sales and payments, payment methods,sales dates and taxes.' /* Description / copy */,
      requiresSelection: false /* Boolean indicating if source requires selection */,
      settings: [
        {
          label: 'STORE' /* Display label of setting input item */,
          group:
            null /* Use this to denote multiple input items under one label or null for individual items */,
          type: 'date' /* HTML input type */,
          placeholder: 'thirdPartyEntities[etsy]:store' /* Placeholder value */,
          data: 'first_import_date' /* 'data' name or object property name for formulating BE request */,
          options: null,
        },
        {
          label: 'INCLUDE TAXES',
          group: null,
          type: 'checkbox',
          placeholder: null,
          data: 'include_taxes',
          options: null,
        },
        {
          label:
            'DATA MAPPING' /* Use 'group' for proper FE rendering of grouped inputs such as Data Mapping */,
          group: [
            {
              label: null /* No label for this specfic field */,
              type: 'select' /* select element */,
              placeholder: 'Source' /* Placeholder option */,
              data: 'data_mapping_source' /* 'data' name or object property name for formulating BE request */,
              options: [
                { name: 'Source A', data: 'source_a' },
                { name: 'Source B', data: 'source_b' },
                { name: 'Source C', data: 'source_c' },
                { name: 'Source D', data: 'source_d' },
              ],
              //  ----> ↓ ↓ ↓ ↓  OR  ↓ ↓ ↓ ↓ <----
              // Send HTTP request details to FE to get options
              /* options : {
                                method : "GET",
                                path : "/api/settings/options?type=etsy&field=data_mapping_source"
                            } */
            },
            {
              label: 'TO' /* Label in group will sit between inputs */,
              type: 'select',
              placeholder: 'Category',
              data: 'data_mapping_dest',
              options: [
                { name: 'Destination A', data: 'dest_a' },
                { name: 'Destination B', data: 'dest_b' },
                { name: 'Destination C', data: 'dest_c' },
                { name: 'Destination D', data: 'dest_d' },
              ],
              //  ----> ↓ ↓ ↓ ↓  OR  ↓ ↓ ↓ ↓ <----
              // Send HTTP request details to FE to get options
              /* options : {
                                method : "GET",
                                path : "/api/settings/options?type=etsy&field=data_mapping_dest"
                            } */
            },
          ],
          type: null,
          placeholder: null,
          data: null,
          options: null,
        },
      ],
    },
    {
      name: 'Stripe' /* Name of integration source */,
      enabled: false /* Enabled/Disabled */,
      description:
        'Connect your Stripe account to help you track additional sources of revenue outside of FreshBooks.' /* Description / copy */,
      requiresSelection: false /* Boolean indicating if source requires selection */,
      settings: null,
    },
    {
      name: 'Square' /* Name of integration source */,
      enabled: false /* Enabled/Disabled */,
      description:
        'Connect your Square account to help you track additional sources of revenue outside of FreshBooks' /* Description / copy */,
      requiresSelection: false /* Boolean indicating if source requires selection */,
      settings: null,
    },
  ],
};
