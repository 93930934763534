// ¯\_(ツ)_/¯ What does this mean/do? vv

class IntegrationSourceSetting {
  constructor(opts) {
    this.getLabel = () => opts.label;
    this.isGroup = () => opts.group !== null;
    if (opts.group === null) {
      this.getGroup = () => null;
    } else {
      this.getGroup = () => opts.group.map((s) => new IntegrationSourceSetting(s));
    }
    this.getType = () => opts.type;
    this.getPlaceholder = () => opts.placeholder;
    this.getData = () => opts.data;
    this.getOptions = () => opts.options;
  }
}

class IntegrationSource {
  constructor(opts) {
    this.getName = () => opts.name;
    this.getEnabledStatus = () => opts.enabled;
    this.getDescription = () => opts.description;
    this.requiresSelection = () => opts.requiresSelection;
    if (opts.settings !== null) {
      this.getSettings = () => opts.settings.map((s) => new IntegrationSourceSetting(s));
    } else {
      this.getSettings = () => null;
    }
  }
}

class IntegrationSourceParser {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  static parse() {}
}

export { IntegrationSource, IntegrationSourceParser };
