import React from 'react';
import { IBusinessMembership } from '../../../types/interfaces';

interface ISelectBusinessProps {
  title: string;
  options: IBusinessMembership[];
  register: (option: IBusinessMembership) => void;
}

const SelectBusiness = ({ title, options, register }: ISelectBusinessProps) => {
  const renderMenuItems = () => {
    return options.map((e, n) => {
      let menuItemClassName = 'x-light fs-item';
      if (options.length === 1) {
        menuItemClassName += ' only';
      } else if (n === 0) {
        menuItemClassName += ' first';
      } else if (n === options.length - 1) {
        menuItemClassName += ' last';
      }

      return (
        <div key={n * 121.46} className={menuItemClassName} onClick={() => register(e)}>
          {e.name}
        </div>
      );
    });
  };
  return (
    <>
      <div className={'fs dialogue md'}>
        <h2 className={'x-light'}>{title}</h2>
        <br />
        {renderMenuItems()}
      </div>
    </>
  );
};
export default SelectBusiness;
