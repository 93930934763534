import React from 'react';

import Dialogues from './components/dialogues';
import ThirdPartyConnector from './components/third_party_connector';

const props_tpc1 = {
  status: 'Completed',
  _id: '5e75317d2cf4ac00086a40e0',
  type: 'etsy',
  entity_1: {
    __t: 'FreshBooksBusiness',
    dateCreated: '2020-03-16T20:48:10.783Z',
    dateUpdated: '2020-03-16T20:48:10.783Z',
    _id: '5e6fe60ab6f93b00073f2740',
    type: 'freshbooks',
    credentials: '5e3b7331c549b40008c2a9a7',
    account_id: 'jRM6xg',
    __v: 0,
  },
  entity_2: {
    __t: 'EtsyShop',
    dateCreated: '2020-02-21T22:06:05.090Z',
    dateUpdated: '2020-02-21T22:06:05.090Z',
    _id: '5e50544da3382490f58ac524',
    type: 'Etsy',
    credentials: '5e29fb203ad7390b76e8dee6',
    shop_id: '22123523',
    __v: 0,
  },
  polling: true,
  dateCreated: '2020-03-20T21:11:25.423Z',
  dateUpdated: '2020-03-20T21:11:25.423Z',
  __v: 0,
  ongoingSync: true,
  lastSyncStart: '2020-03-21T23:37:52.401Z',
  amounts: { USD: 0.25 },
  lastSyncEnd: '2020-03-21T23:37:52.828Z',
};

class View extends React.Component {
  constructor(props) {
    super(props);

    this.exampleBusinessArray = [
      {
        name: 'Test Business One',
        id: 'a1b2c3d4e5',
      },
      {
        name: 'Test Business Two',
        id: 'a1b2c3d4e5',
      },
      {
        name: 'Test Business Three',
        id: 'a1b2c3d4e5',
      },
      {
        name: 'Test Business Four',
        id: 'a1b2c3d4e5',
      },
    ];

    this.viewableComponents = [
      {
        name: 'Dialogues - Login',
        component: Dialogues.Login,
      },
      {
        name: 'Dialogues - Loading',
        component: Dialogues.Loading,
      },
      {
        name: 'Dialogues - Select Business',
        component: () => (
          <Dialogues.SelectBusiness
            title="Select a Business"
            options={this.exampleBusinessArray}
            register={() => null}
          />
        ),
      },
      {
        name: 'Dialogues - Other Income Main ( Wrapper )',
        component: Dialogues.Dashboard,
      },
      {
        name: 'Dialogues - Sync In Progress',
        component: Dialogues.ActionInProgress,
      },
      {
        name: 'Dialogues - Sync Complete',
        component: Dialogues.SyncComplete,
      },
      {
        name: 'Dialogues - Confirm Disconnect',
        component: Dialogues.ConfirmDisconnect,
      },
      {
        name: 'Elements - Third Party Connector',
        component: () => (
          <>
            <ThirdPartyConnector props={props_tpc1} />
          </>
        ),
      },
    ];
    this.state = {
      component: null,
    };
  }

  setComponent(c) {
    this.setState({ component: c });
  }

  getComponent() {
    for (let i = 0; i < this.viewableComponents.length; i++) {
      if (this.viewableComponents[i].name === this.state.component) {
        let Component = this.viewableComponents[i].component;
        return <Component />;
      }
    }
    return <></>;
  }

  render() {
    return (
      <div id={'view-main'}>
        <div id={'view-toolbar'}>
          <div className={'view-toolbar-label'}>View</div>
          <div className={'view-toolbar-item'}>
            <select onChange={(e) => this.setComponent(e.target.value)}>
              <option value={null}> Select a Component </option>
              {this.viewableComponents.map(({ name }, n) => {
                return (
                  <option key={n * 256} value={name}>
                    {' '}
                    {name}{' '}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        {this.getComponent()}
      </div>
    );
  }
}

export default View;
